<template>
  
</template>
<script>
    import { StatisticsTime } from "../components/statisticsTime/StatisticsTime"
    import storage from '../utils/customstorage'
    export default {
        name: "statisTime",
        data() {
            var staticTime=new StatisticsTime(this.TokenClient, this.Services.Statics)
            var myDate = new Date()
            var userInfo=this.$store.state.localData.userInfo
            return {
                staticTimeDomain:staticTime,
                dateNow:this.$moment(myDate).utcOffset(480).format("YYYY-MM-DD HH:mm:ss"),
                browseTime: 0,  // 浏览时长初始值为 0
                clearTimeSet: null,
                _beforeUnload_time:null,
                userId:userInfo.id,
                hospitalId:userInfo.organizationId ?? 0,
                departmentId:userInfo.departmentId ?? 0
            };
        },
        methods: {
            setTime() {
                //设置定时器
                this.clearTimeSet = setInterval(() => {
                    this.browseTime++;
                }, 1000);
            },
            putTime(entityData) {
                var _this = this;
                if (entityData) {
                    var entityType = 0;
                    if (entityData.entityType) {
                        if (entityData.entityType.indexOf("西医") != -1) {
                            entityType = 1;
                        }
                        if (entityData.entityType.indexOf("中医") != -1) {
                            entityType = 2;
                        }
                        if (entityData.entityType.indexOf("化学药品和生物制品") != -1) {
                            entityType = 3;
                        }
                        if (entityData.entityType.indexOf("中成药") != -1) {
                            entityType = 4;
                        }
                    }
                    _this.staticTimeDomain.Put(entityData.type, entityData.name, entityData.hashKey, entityData.id,
                     entityType, _this.dateNow, _this.browseTime,_this.userId,_this.hospitalId,_this.departmentId,
                        function (data) {
                        }, function (err) {
                            console.log(err)
                        });
                }
            },
            save() {
                clearInterval(this.clearTimeSet); // 离开页面后清除定时器
                this.putTime(this.$parent.entityData)
            },
            beforeunloadHandler() {
                this._beforeUnload_time = new Date().getTime();
            },
            unloadHandler(e) {
                this._gap_time = new Date().getTime() - this._beforeUnload_time;
                debugger
                this.save();
            }
        },
        mounted() {
            this.setTime(); // 页面加载完成后开始计时
            window.addEventListener('beforeunload', this.unloadHandler, false);
        },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.unloadHandler, false);
            this.save();
        },
        destroyed() {
            window.removeEventListener('beforeunload', this.unloadHandler, false);
        }
    }
</script>