let HealthyDiet = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取中药方剂搜索列表数据
     * @param {any} success
     * @param {any} error
     */
    this.HealthyDiets = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/HealthyDiet/Search' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取中药方剂热词数据
     * @param {any} success
     * @param {any} error
     */
    this.Hotspot = function (success, error) {
        var url = ServiceBaseAddress + '/api/HealthyDiet/Hotspot';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取中药方剂分类
     * @param {any} success
     * @param {any} error
     */
    this.Categorys = function (success, error) {
        var url = ServiceBaseAddress + '/api/HealthyDiet/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取中药方剂详情数据
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/HealthyDiet/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { HealthyDiet }