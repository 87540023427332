<template>
    <div class="food zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>{{ healthyDetail.name }}</h3>
                <p>
                    当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/Drug/HealthyDiet">健康饮食
                    </router-link><i class="el-icon-arrow-right"></i><a>{{ healthyDetail.name }}</a>
                </p>
                <div class="banner-right">
                    <span @click="onCompatibility(healthyDetail)">加入配伍</span>
                    <span v-if="!exist" @click="onCollection(healthyDetail)">加入收藏</span>
                    <span v-else @click="deleteAll()">取消收藏</span>
                </div>
            </div>
        </div>
        <!--    内容部分    ?-->
        <div class="content-wrap">
            <div class="content clearfix">
                <el-scrollbar class="left-nav-wrap">
                    <!-- 具体要包裹的内容 -->
                    <div class="fast-nav">
                        <ul class="timeline is-reverse">
                            <li class="timeline-item" v-for="(item, index) in healthyDetail.infos" :key="index"
                                @click="jump(index)">
                                <div class="timeline-item__tail" v-if="index != healthyDetail.infos.length - 1"></div>
                                <div class="timeline-item__node"></div>
                                <div class="timeline-item__text">
                                    <h3 data-size="18">{{ item.title }}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-scrollbar>
                <div class="detail-box">
                    <div class="sd-div" v-for="(item, index) in healthyDetail.infos" :key="index">
                        <h3 class="sd-tit" data-size="22">{{ item.title }}</h3>
                        <div class="sd-mes" >
                            <p data-size="16" v-html="replace(item.info)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
        <staticTime></staticTime>
    </div>
</template>

<script>
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
import rtBar from "../../components/right";
import staticTime from "../../components/staticTime";
import { HealthyDiet } from '../../components/Drug/HealthyDiet'
import { DrugExtend } from "../../components/Drug/DrugExtend"
import { toMaterial, toFood, toHerbal, toDrug, toMaterialMore, toFoodMore, toHerbalMore, toDrugMore } from "../../utils/relation";
import { Collection } from "../../components/collection/Collection"
import { addCompare, getCompatibilityList } from '../../utils/Compatibility'
export default {
    name: "HealthyDetail",
    components: {
        hdCom,
        ftCom,
        rtBar,
        staticTime
    },
    data() {
        var healthy = new HealthyDiet(this.TokenClient, this.Services.Drug)
        var drugExtend = new DrugExtend(this.TokenClient, this.Services.Drug)
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            id: this.$route.query.id, //列表页id
            searchId: this.$route.query.searchId, //列表页searchId
            keyWord: this.$route.query.keyWord, //列表页KeyWord
            tableData: [], //初始化组成表格数据
            healthyDetail: {}, //初始化详情
            drugRelations: {}, //初始化同类药
            healthyDomain: healthy,
            drugExtendDomain: drugExtend,
            scroll: '',
            collectionDomain: collection,
            entityData: {},
            fontSize: "",
            exist: false,
            collectionId: ""
        };
    },
    methods: {
        onCompatibility: function (item) {
            var compatibility = getCompatibilityList();
            if (compatibility.length > 0) {
                if (compatibility.some(i => i.hashKey == item.hashKey && i.type == '5')) {
                    this.$message.error('已加入用药审查！');
                    return false;
                }
            }
            addCompare(item.hashKey, item.name, 1, "HealthyDiet");
            this.$message.success('成功加入用药审查！');
            this.$bus.$emit('right', 'reload')
        },
        getFontSize(data) {
            this.fontSize = data
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".left-nav-wrap");
            let banner = document.querySelector(".banner");
            //scrollObj 这个是下图右边答题卡的div

            if (scrollY > 80) {
                banner.style.position = "fixed";
                banner.style.top = 0;
                banner.style.zIndex = 99;
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "180px";
            } else {
                banner.style.position = "initial";
                banner.style.top = "none";
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        checkExist() {
            var _this = this;
            _this.collectionDomain.Exist(this.entityData.type, this.entityData.hashKey, this.entityData.id,
                function (data) {
                    _this.exist = data.data.exist
                    _this.collectionId=data.data.collectionId
                    
                }, function (err) {
                    
                });
        },
        //详情获取
        getHealthyDetail: function () {
            var _this = this;
            let params = `${this.id}`;
            _this.healthyDomain.Detail(params, function (data) {
                _this.healthyDetail = data.data;
                _this.entityData = {
                    type: "healthy",
                    name: _this.healthyDetail.name,
                    hashKey: _this.healthyDetail.hashKey,
                    id: _this.healthyDetail.id,
                }
                _this.checkExist()
                _this.$bus.$emit('fontSize');
            },
                function (error) {
                    console.log("健康饮食详情获取失败:", error)
                })
        },
        deleteAll: function () {
            var _this = this;
            var idarr = [_this.collectionId]
            _this.collectionDomain.Delete(idarr, function (data) {
                    _this.checkExist()
                    _this.$message({
                        center: true,
                        type: 'success',
                        message: '取消成功!'
                    });
                },
                    function (error) {
                        console.log(error);
                    })
        },
        //收藏
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("healthy", item.name, item.hashKey, item.id, 0,
                function (data) {
                    _this.checkExist()
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if (err.status === 400) {
                        _this.$message.error('请勿重复收藏');
                    }
                });
        },
        //跳转同类药-中药更多
        toMaterialMore(cateid) {
            toMaterialMore(cateid);
        },
        //跳转同类药-中药详情
        toMaterialDetail(id) {
            toMaterial(id, this.searchId, this.keyword);
        },
        //跳转同类药-食疗药膳更多
        toFoodMore(cateid) {
            toFoodMore(cateid);
        },
        //跳转同类药-食疗药膳详情
        toFoodDetail(id) {
            toFood(id, this.searchId, this.keyword);
        },
        //跳转同类药-中药方剂更多
        toHerbalMore(cateid) {
            toHerbalMore(cateid);
        },
        //跳转同类药-中药方剂详情
        toHerbalDetail(id) {
            toHerbal(id, this.searchId, this.keyword);
        },
        //跳转同类药-中西药更多
        toDrugMore(cateid, type) {
            toDrugMore(cateid, type);
        },
        //跳转同类药-中西药详情
        toDrugDetail(id) {
            toDrug(id, this.searchId, '');
        },
        replace: function (content) {
            return content.replace(/\n/g, '</p><p>');
        },
        dataScroll: function () {
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        jump(index) {
            let jump = document.getElementsByClassName('sd-div');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop - 80;
            window.scroll(0, total)
            // Chrome
            //document.body.scrollTop = total;
            // Firefox
            //document.documentElement.scrollTop = total;
            // Safari
            //window.pageYOffset = total;
            // $('html, body').animate({
            // 'scrollTop': total
            // }, 400);

        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".timeline .timeline-item");
            var sections = document.getElementsByClassName('sd-div');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 80) {
                    $navs.eq(i).addClass("active").siblings().removeClass("active")
                    break;
                }
            }
        }
    },
    created() {
        let _this = this;
        window.toMaterialDetail = _this.toMaterialDetail;
    },
    watch: {
        scroll: function () {
            this.loadSroll();
        },
    },
    mounted() {
        this.$bus.$emit('select', 'all')
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.dataScroll);
        this.getHealthyDetail();
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.dataScroll);
    },
};
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

/*banner*/
.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/zyk_banner.jpg")center no-repeat;
}

.banner-text {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 45px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text h3 span {
    font-size: 14px;
}

.banner-text p {
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 65px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

/*内容部分*/
.content-wrap {
    background: #f8f8f8;
}

.content-wrap .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: rgba(255, 255, 255, 0);
}

.detail-box {
    margin-right: 260px;
    padding: 0px 35px;
    width: 830px;
    background: #ffffff;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.sd-div {
    padding: 30px 0;
    border-top: 1px solid #e5e5e5;
}

.sd-div:first-child {
    border-top: 0px;
}

.sd-div .sd-tit {
    font-size: 24px;
    color: #333333;
    font-weight: normal;
    margin-bottom: 30px;
}

.sd-div .sd-tit.overview {
    font-size: 26px;
    color: #333333;
    padding-left: 15px;
    border-left: 3px solid #333;
    line-height: 26px;
}

.sd-div .sd-mes p {
    white-space: pre-line;
}

.hxsw li {
    margin: 5px;
    display: inline-block;
    height: 42px;
    border: 1px solid #666666;
    padding: 0 18px;
    box-sizing: border-box;
}

.hxsw li a {
    line-height: 42px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    cursor: pointer;
}

.hxsw li:hover {
    border-color: #00ab98;
}

.hxsw li:hover a {
    color: #00ab98;
}

.zhong .hxsw li:hover {
    border-color: #b76c12;
}

.zhong .hxsw li:hover a {
    color: #b76c12;
}

.zhongyao {
    text-align: center;
}

.zhongyao li {
    float: left;
    width: 184px;
    height: 184px;
    margin: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}

.zhongyao li .zhongyao-img {
    width: 120px;
    height: 120px;
    margin: 15px auto 5px;
}

.zhongyao li .zhongyao-img img {
    width: 100%;
    height: auto;
}

.zhongyao li .zhongyao-name {
    font-size: 18px;
    text-align: center;
    color: #333333;
}

.zhongyao li:hover {
    border-color: #00ab98;
    cursor: pointer;
}

.zhongyao li:hover .zhongyao-name {
    color: #00ab98;
}

.zhongyao .more-btn {
    display: inline-block;
}

.zhong .more-btn {
    margin: 10px auto;
    display: inline-block;
    width: 124px;
    height: 38px;
    line-height: 38px;
    background-color: #b76c12;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
}

.left-nav-wrap {
    position: absolute;
    margin-left: 950px;
    width: 260px;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    background: #FFFFFF;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
    max-height: 470px;
    overflow-x: hidden;
}

.fast-nav {
    width: 250px;
    box-sizing: border-box;
    padding: 30px 0 30px 40px;
    z-index: 10;
}

.timeline-item {
    position: relative;
    padding-bottom: 20px;
    padding-top: 10px;
    cursor: pointer;
}

.timeline-item__tail {
    position: absolute;
    top: 15px;
    left: 3px;
    height: 100%;
    border-left: 1px solid #e5e5e5;
}

.timeline-item__node {
    position: absolute;
    background-color: #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15px;
    left: -1px;
    width: 9px;
    height: 9px;
}

.timeline-item__text {
    position: relative;
    padding-left: 28px;
    top: -3px;
}

.timeline-item__text h3 {
    font-size: 18px;
    color: #333;
    font-weight: normal;
}

.timeline-item.active .timeline-item__node,
.timeline-item:hover .timeline-item__node {
    background-color: #b76c12;
}

.timeline-item.active .timeline-item__text h3,
.timeline-item:hover .timeline-item__text h3 {
    color: #b76c12;
}

.zhong .food-list .food-item {
    position: relative;
    padding: 15px 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.zhong .food-list .food-item:hover {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.zhong .food-tit {
    font-size: 18px;
    font-weight: normal;
}

.zhong .food-tit span {
    font-size: 12px !important;
    color: #fff;
    background-color: #b76c12;
    border-radius: 10px;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    padding: 0 4px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.zhong .food-item p {
    margin-top: 10px;
    color: #666666;
}

.zhong .food-item p em {
    font-style: normal;
}
</style>
<style>

.food .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.food .el-collapse-item__header:hover {
    color: #ffffff;
}

.food .el-collapse-item__wrap {
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
}

.food .el-collapse-item__content {
    padding: 15px 5px;
}

.el-table td div a {
    color: #b76c12;
}

.el-table td div a:hover {
    text-decoration: underline;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
