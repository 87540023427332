let Collection = function (tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr

	/**
	 * 获取收藏列表数据
	 * @param {any} success
	 * @param {any} error
	 */
	this.Collections = function (PageIndex, type, success, error) {
		var url = ServiceBaseAddress + '/api/Collection/' + PageIndex + '/' + type
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 收藏
	 * @param {any} success
	 * @param {any} error
	 */
	this.Put = function (
		type,
		entityName,
		entityKey,
		entityId,
		entityType,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/Collection/Put'
		var param = {
			type: type,
			entityName: entityName,
			entityKey: entityKey,
			entityId: entityId,
			entityType: entityType,
		}
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 查询是否收藏
	 * @param {any} success
	 * @param {any} error
	 */
	this.Exist = function (
		type,
		entityKey,
		entityId,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/Collection/Exist'
		var param = {
			type: type,
			entityKey: entityKey,
			entityId: entityId,
		}
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 删除
	 * @param {any} success
	 * @param {any} error
	 */
	this.Delete = function (referralId, success, error) {
		var url = ServiceBaseAddress + '/api/Collection/RemoveAll/' + referralId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
}

export { Collection }
